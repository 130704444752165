import { React, useEffect, useState } from "react";

import {
  // AppBar,
  // Toolbar,
  // Button,
  Switch,
  FormControlLabel,
} from "@mui/material";

function ThemeToggle() {
  const [theme, setTheme] = useState("dark");

  useEffect(() => {
    if (theme === "dark") {
      document.querySelector("html").className = "dark";
    } else {
      document.querySelector("html").className = "light";
    }
  }, [theme, setTheme]);

  return (
    <div>
      {" "}
      <div>
        {/* <Button
    variant="primary"
    
    onClick={() => {
      setTheme(theme === "light" ? "dark" : "light");
    }}
    className="text-on-bg"
  >
    Change Theme
  </Button> */}

        <FormControlLabel
          control={
            <Switch
              onClick={() => {
                setTheme(theme === "light" ? "dark" : "light");
              }}
              // checked={state.checkedB}
              // onChange={handleChange}
              name="checkedB"
              // color="primary"
              className="toggle-button "
            />
          }
          label=""
        />

        {/* <FormControlLabel control={<Switch />} label="Uncontrolled" /> */}
        {/* <FormControlLabel disabled control={<Switch />} label="Disabled" /> */}
        {/* <FormControlLabel disabled control={<Switch checked />} label="Disabled" /> */}
      </div>
    </div>
  );
}

export default ThemeToggle;
