import React from "react";

import {
  AppBar,
  Toolbar,
  // Button
} from "@mui/material";

import JI_Icon from "../../assets/logos/JhonInalienDevIcon.svg";
// import NavData from "./nav_data.json";

import ThemeToggle from "../../components/theme_toggle.js";

// import MoreIcon from "@material-ui/icons/MoreVert";

function Nav() {
  return (
    <AppBar>
      {/* <Toolbar>
          <Button variant="outlined" color="inherit">
            Button 1
          </Button>
          <Button variant="outlined" color="inherit">
            Button 2
          </Button>
        </Toolbar>
      </AppBar> */}

      {/* 

*/}
      <>
        <Toolbar className="container">
          <a href="#cover" className="app-bar-logo" target="_self">
            <img src={JI_Icon} alt="Jhon's Logo" />
          </a>

          {/*  */}
          {/* <div className={classes.grow} /> */}
          {/*  */}

          {/* <div className={classes.sectionDesktop}>
          {NavData.map((NavData) => (
       

            <a
              href={NavData.href}
              data-content={NavData.page}
              className="btn-app-bar"
              target={NavData.target}
            >
              {NavData.page}
            </a>
          ))}
        </div> */}

          {/*  */}

          <div style={{ marginLeft: "80%" }}>
            <ThemeToggle />
          </div>

          {/*  */}

          {/* <ThemeProvider
          theme={isDark ? lightTheme : darkTheme }
          setTheme={setTheme}
        >
          <GlobalStyle />
          <ThemeSwitch theme={theme} toggleTheme={toggleTheme} />
        </ThemeProvider> */}

          {/*  */}

          {/* <div className={classes.sectionMobile}>
          <IconButton
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
            color="inherit"
          >
            <MoreIcon />
          </IconButton>
        </div> */}

          {/*  */}
        </Toolbar>
        {/* </AppBar> */}

        {/*  */}
        {/* {renderMobileMenu} */}
        {/*  */}
      </>
    </AppBar>
  );
}

export default Nav;
