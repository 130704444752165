import React from "react";
import { Typography, Grid } from "@mui/material";
import Typer from "../../components/typer/typerEffect.js";

import "./hero.scss";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import Link from "@mui/material/Link";

import JhonAvatar from "../../components/avatarImg/avatarImg";

function Hero() {
  return (
    <div className="section">
      <div className="hero center-vertical ">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid
            item
            xs={12}
            sm={6}
            id="content-mobile"
            className="center-vertical"
          >
            <JhonAvatar />
          </Grid>

          <Grid item xs={12} sm={6} className="top">
            <Typography variant="h5" className="color-tertiary subtitle">
              Welcome!
            </Typography>
            <Typography variant="h1" className="color-secondary">
              Hi, I’m <span className="color-primary">Jhon Inalien</span>
            </Typography>

            <Typography variant="h2" className="color-secondary subtitle">
              a{" "}
              <span>
                <Typer
                  loop={true}
                  speed={150}
                  delay={2000}
                  words={[
                    "Software Engineer.",
                    "Front End Developer.",
                    "SDET.",
                    "Freelancer.",
                  ]}
                />
              </span>
            </Typography>

            <Typography
              variant="body1"
              className="color-tertiary  padding-small"
            >
              I am a resourceful Software Engineer using knowledge of the latest
              technology trends to produce clean and responsive website design
              and automated testing.
            </Typography>

            <div className="subtitle padding-med">
              <Grid
                container
                rowSpacing={1}
                spacing={12}
                columnSpacing={{ xs: 5 }}
              >
                <Grid item xs={12} md={6}>
                  <Typography className="color-tertiary subtitle" variant="h5">
                    Lets Connect
                  </Typography>
                  <box className="icon-box shadow">
                    <Link
                      href="https://www.linkedin.com/in/jhon-inalien-dev/"
                      target="_blank"
                    >
                      <LinkedInIcon className="icon" />
                    </Link>
                  </box>
                  <box className="icon-box shadow">
                    {" "}
                    <Link
                      href="https://github.com/inalien"
                      target="_blank"
                      fontStyle="large"
                    >
                      <GitHubIcon fontStyle="large" className="icon" />
                    </Link>
                  </box>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={5} className=" center-vertical " id="content-desktop">
            <JhonAvatar />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Hero;
