import React from "react";
import JhonImage from "../../assets/photos/JIFullBW.png";

import "./avatarImg.scss";
function AvatarImg() {
  return (
    <div className="circle-inner">
      <div className="circle" />
      <img className="img-pop " src={JhonImage} alt="Jhon's avatar" />
    </div>
  );
}

export default AvatarImg;
