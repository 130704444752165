import React from "react";
import "../src/styles/theme.scss";

import Nav from "../src/views/nav/nav";
import Hero from "./views/hero/hero";
function App() {
  return (
    <div className="container">
      <Nav />
      <Hero />
    </div>
  );
}

export default App;
